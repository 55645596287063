import { graphql, useStaticQuery } from 'gatsby';
import React, { useEffect, useRef } from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { BringMeBack } from '../components/shared/bring-me-back';
import SigmaWodkaVid from '../images/videos/sigma_wodka_vid.mp4';

export const query = graphql`
  query {
    sigmaWodka: file(relativePath: { eq: "sigma_wodka.png" }) {
      childImageSharp {
        fluid(maxWidth: 700) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

const moreThanRussianStandard = (props: any) => {
  const data = useStaticQuery(query);
  const ref = useRef<HTMLVideoElement>(null);
  useEffect(() => {
    ref.current?.play;
  });

  return (
    <Layout>
      <SEO
        keywords={[`Bar Sigma Wodka`, `Wodka`, `Getränke`]}
        title="Wodka essentials"
      />
      <section className="mt-28">
        <div className="container">
          <div className="header">
            <h1>MORE THAN RUSSIAN STANDARD: SIGMA WODKA</h1>
          </div>
          <div className="grid grid-cols-6 gap-7">
            <div className="col-span-6 lg:col-span-4 lg:text-right text-justify">
              <p>
                “Wenn ich groß bin, werde ich Filmstar.” Okay, aber was ist das
                wahre Life Goal mit Ende 20? Eine Spirituose mit dem eigenen
                Etikett in den Händen zu halten oder noch besser: in der eigenen
                Bar stehen zu haben. Das Klischéeprodukt #1 aus dem Ostblock ist
                mit unserem Namen versehen. #mamaiststolz
              </p>
              <p>
                Nach der grandiosen Idee “Lasst uns ‘ne Bar aufmachen” mussten
                wir uns natürlich damit beschäftigen, was wir überhaupt
                ausschenken möchten. Klar, Wodka muss am Start sein. Aber
                welcher? Die Regel #1 aus unseren Elternhäusern: Ein Wodka, der
                keine 40% Vol. Alc. hat, ist kein Wodka. Okay, das heißt alle
                Spirituosen mit den bekannten 37,5% Vol. Alc. fallen schon mal
                aus dem Spiel. Ein paar Anfragen und Konkurrenzanalysen später
                waren wir uns einig: Lass mal was eigenes kreieren.
              </p>
              <p>
                Durch Zufall haben wir unsere kleine Destillerie gefunden, die
                im Familienbetrieb geführt wird. Eine Sigma-Reise, ein paar
                Tastings und eine Führung durch die heiligen Hallen später,
                wollten wir unseren Sigma Wodka genau hier entstehen lassen. Und
                es passt einfach ins Konzept: Der einzigartige Geschmack lässt
                unsere Drinks sigmature werden. The name is real: Unseren Sigma
                Pitcher, Wodka Red Bull, Wodka Soda uvm. könnt ihr für eure
                Houseparty nicht im Rewe nachkaufen. Ihr kriegt das Erlebnis nur
                in der Bar SIGMA und das ist ein Grund, zu uns zu kommen.
              </p>
              <br />
              <p>Also, schon probiert? </p>
              <p>На здоровье!</p> <p>Anastasia, Lisa, Margarita & Sophia.</p>
            </div>
            <div className="col-span-6 lg:order-2 order-first lg:col-span-2">
              <video
                className="max-w-xs lg:max-w-full mx-auto"
                ref={ref}
                autoPlay={true}
                muted={true}
                controls={false}
                loop={true}
                playsInline={true}
                onContextMenu={(e) => e.preventDefault()}
              >
                <source src={SigmaWodkaVid} type="video/mp4" />
              </video>
            </div>
          </div>
        </div>
      </section>
      <div className="container">
        <BringMeBack></BringMeBack>
      </div>
    </Layout>
  );
};
export default moreThanRussianStandard;
